<template>
    <header class="header">
        <base-container>
            <div class="main-header">
                <div class="img">
                    <img class="logo" src="" alt="Logo">
                </div>
                <ul class="media">
                    <li><a href="https://hasanyameen.com/" target="_blank"><font-awesome-icon
                                :icon="['fas', 'globe']" /></a></li>
                    <!-- <li><a href="https://www.facebook.com/" target="_blank"><font-awesome-icon
                                :icon="['fab', 'facebook-f']" /></a></li> -->
                    <li><a href="https://www.instagram.com/hasankyamin/" target="_blank"><font-awesome-icon
                                :icon="['fab', 'instagram']" /></a></li>
                    <!-- <li><a href="https://twitter.com/" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
                    </li> -->

                </ul>
            </div>
            <nav class="nav-bar" id="nav-bar">
                <div class="media_button">
                    <button class="main_media_button" id="mediaButton" @click="() => this.showMenu = !this.showMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <ul :class="{ showList: showMenu }">
                    <li><router-link to="/">Buy</router-link> </li>
                    <li><router-link to="/Sell">Sell</router-link></li>
                    <li><router-link to="/rent">rent</router-link></li>
                    <li><router-link to="/mortgate">mortgate</router-link></li>

                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/brands">Products</router-link></li>
                    <li><router-link to="/solutions">Solutions</router-link></li>
                    <li><router-link to="/projects">References</router-link></li>
                </ul>

                <span class="contact"><router-link to="/contactus">Contact Us</router-link></span>
            </nav>
        </base-container>
    </header>
</template>
  
<script >
export default {
    data() {
        return {
            showMenu: false
        }
    },
}
</script>
  
<style scoped lang="scss">
@import '../scss/vars';

header {
    width: 100%;
    position: relative;
    z-index: 10;

    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        color: $main_color;
        height: 70px;

        .img {
            width: 150px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                width: 100px;
            }

            .logo {
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: contain;
            }
        }

        .media {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            li {

                padding: 5px;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    color: $main_color;
                }

            }
        }
    }

    .nav-bar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            list-style: none;

            @media (max-width: 767px) {
                display: none;
            }

            &.showList {
                // transition: all 1s ease-in-out;
                display: flex;
                flex-direction: column;
                width: 90%;
                padding: 10px;
                // background: linear-gradient(90deg, rgba(82, 75, 46, 0.673) 0%, rgba(240, 241, 241, 0.192) 100%);
                background-color: rgba(69, 43, 43, 0.724);
                gap: 5px;
                position: relative;
                z-index: 1000;
            }

            li {
                // font-size: 15px;
                line-height: 65px;
                text-align: center;

                @media (max-width: 767px) {
                    width: 100%;
                    line-height: 20px;
                    background-color: $alt_11_BG_color;

                    &:hover {
                        background-color: $alt_1_BG_color;

                        a {
                            color: $main_color;
                        }
                    }
                }

                a {
                    font-size: 18px;

                    &:hover {
                        color: $main_BG_color;
                    }
                }

            }
        }

        .contact {
            background-color: $main_BG_color;
            border: none;
            background-color: $main_BG_color;
            color: $main_color;
            border-radius: 15px;
            cursor: pointer;
            padding: 5px 15px 5px 15px;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 14px, rgba(0, 0, 0, 0.09) 0px 5px 8px, rgba(239, 219, 142, 0.4745098039) 0px 10px 15px;

            @media (max-width: 767px) {
                padding: 5px;

            }

            a {
                font-size: 18px;
                color: $main_color;

                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
        }

        .media_button {
            width: 40px;
            background-color: transparent;
            display: none;
            justify-content: center;
            align-items: flex-start;

            @media (max-width: 767px) {
                display: flex;
                height: 70px;
            }

            .main_media_button {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                background-color: transparent;
                border: none;
                cursor: pointer;

                span {
                    width: 100%;
                    height: 5px;
                    border-radius: 6px;
                    background-color: #000;
                }

                &:hover {
                    transform: rotateY(180deg);
                    transition: all 0.5s;

                    span {
                        background-color: #c0c0c0;
                    }
                }
            }
        }
    }
}
</style>  