<template>
  <div class="header-img">
    <the-header />
  </div>
  <base-container>
    <div class="about">
      <section class="mind">
        <div class="main-img">
          <img :src="aboutImg" alt="">
        </div>
        <div class="text">
          <h2 class="title">Peace of mind</h2>
          <span class="sub-title">about the company</span>
          <p class="description">We are a group of people who are ambitious, experienced and hard-working. Our team is
            mainly
            focused on home & building automation since 2017, AL-FANAR CO. is an intelligent service
            provider in the Palestine. With years of experience in the intelligent industry, it is committed to
            the field of smart home, smart building, smart hotel, management parking solution and media
            facade lighting system. providing the market with comprehensive intelligent control system and
            integrated solutions, helping customers to achieve the comfortable, economical, and reduce
            energy consumption costs in their building.</p>
        </div>

      </section>
      <section class="increase">
        <div class="main-img"><img :src="increasearrow" alt=""></div>
        <div class="text">

          <h2 class="title">we are proud to have uniqu touvh in Palestine market</h2>

          <ul class="description">
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
          </ul>
        </div>

      </section>
      <section class="mission">
        <div class="main-img"><img :src="shok" alt=""></div>
        <div class="text">

          <h2 class="title">our mission</h2>

          <ul class="description">
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
            <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum unde voluptatem temporibus eius dolor
              debitis repellendus ut necessitatibus quam, vel reiciendis ex molestias earum distinctio pariatur numquam
              illum natus aut?</li>
          </ul>
        </div>

      </section>
    </div>
  </base-container>
</template>
<script>
import abouImg from '../svg/about.svg'
import increasearrow from '../svg/about2.svg'
import shok from '../svg/about3.svg'
export default {
  data() {
    return {
      aboutImg: abouImg,
      increasearrow: increasearrow,
      shok: shok,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../scss/vars';

.header-img {
  background-image: url('../assets/images/Untitled-2-01.jpg');
  background-size: cover;
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 0 0 15% 50%/0 0 30% 60%;


  @media (max-width: 767px) {
    border-radius: 0;
    height: 150px;
    overflow: visible;

  }

  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  margin: 100px auto 200px;
  padding: 20px;

  section {
    display: flex;
    // justify-content: space-between;
    align-items: center;


  }

  .mind {
    flex-direction: row-reverse;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .main-img {
      min-width: 25%;

      img {
        width: 100%;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
      }

      .sub-title {
        color: $alt_3_color;
        text-transform: capitalize;
      }

      .description {
        text-align: justify;
        line-height: 22px;
      }
    }
  }

  .increase {
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .main-img {
      min-width: 25%;

      img {
        width: 100%;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
      }


      .description {
        // text-align: justify;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style-type: disc;

        li {
          text-align: justify;
        }
      }
    }
  }

  .mission {
    flex-direction: row-reverse;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .main-img {
      min-width: 25%;

      img {
        width: 100%;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
      }


      .description {
        // text-align: justify;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style-type: disc;

        li {
          text-align: justify;
        }
      }
    }
  }
}
</style>