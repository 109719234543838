<template>
    <div class="header-img">
        <the-header />
    </div>
    <base-container>
        <div class="brand-header">
            <h3>Categories</h3>
            <div class="brand-name">
                <div class="logo"><img :src="brand.logo" alt=""></div>
                <div class="name">{{ currentCategory }}</div>
            </div>
        </div>
        <section class="categories-products">
            <ul class="categories">
                <li v-for="category in categories" :key="category.id" @click="getProducts(category.id, category.name)">
                    {{ category.name }}
                </li>
            </ul>
            <div class="products">
                <div class="product" v-for="product in products" :key="product.id">
                    <div class="main-img">
                        <img :src="product.product_photos[0].image" alt="Product logo">
                    </div>
                    <h3 class="title">{{ product.name }}</h3>
                    <p class="description">{{ product.short_description }}</p>

                    <router-link class="review" :to="getProduct(product.id)">
                        <span>Review <font-awesome-icon :icon="['fas', 'circle-chevron-right']" /></span>
                    </router-link>

                </div>
            </div>
        </section>

    </base-container>
</template>
    
<script >

export default {
    props: ['brandId'],
    data() {
        return {
            currentCategory: ''
        }
    },

    computed: {
        brand() { return this.$store.getters["brands/brand"]; },
        categories() {
            let allCat = this.$store.getters["brands/categories"];
            // console.log('Cats: ', allCat)
            if (allCat.length > 0) {
                // this.currentCategory = allCat[0].name
                this.getProducts(allCat[0].id, allCat[0].name)
            }
            return allCat;
        },
        products() { return this.$store.getters["brands/products"]; },
    },


    created() {
        this.$store.dispatch('brands/resetProducts');

        this.loadBrand();
        this.loadCategories();
    },
    methods: {
        async loadBrand() {
            try {
                await this.$store.dispatch('brands/loadBrand', { brandId: this.brandId });
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }
        },
        async loadCategories() {
            try {
                await this.$store.dispatch('brands/loadCategories', { brandId: this.brandId });
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }
        },
        async getProducts(categoryId, categoryName) {
            // console.log('Products for Category : ' + categoryId)
            // console.log(' Category Name : ' + categoryName)

            this.currentCategory = categoryName

            try {
                await this.$store.dispatch('brands/loadProducts', { categoryId: categoryId });
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }
        },
        getProduct(productId) {
            return `/products/${productId}`
        },

    },

};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.header-img {
    background-image: url('../../assets/images/Untitled-2-01.jpg');
    background-size: cover;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15% 50%/0 0 30% 60%;

    @media (max-width: 767px) {
        border-radius: 0;
        overflow: visible;
        height: 150px;
    }

    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

    }
}

.brand-header {
    h3 {
        padding-left: 50px;
        text-align: left;
        font-size: 30px;
        line-height: 30px;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: 20px;

    }

    .brand-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $alt_color;
        border-radius: 25px;
        padding: 10px 50px;

        .logo {
            width: 100px;

            img {
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: contain;
            }
        }

        .name {
            color: $alt_2_color;
            font-size: 26px;
            text-transform: capitalize;
        }
    }
}

.categories-products {
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }

    .categories {
        width: 300px;
        display: flex;
        flex-direction: column;

        li {
            background-color: $alt_color;
            color: $alt_2_color;
            border: 1px solid $alt_2_color;
            padding: 5px 0;
            line-height: 50px;
            text-transform: capitalize;
            cursor: pointer;
            border-radius: 0;

            &:hover {
                background-color: $alt_1_BG_color;
                color: $alt_color;
                box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
            }
        }
    }

    .products {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 40px;

        .product {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
            padding: 5px;

            &:hover {
                transform: scale(1.1);
            }

            .main-img {
                width: 100%;
                height: 150px;

                img {
                    height: 100%;
                    aspect-ratio: 1/1;
                    object-fit: contain;

                }
            }

            .title {
                text-transform: capitalize;
                color: $alt_color;
                margin: 15px auto;
                font-size: 15px;
                color: $main_color;

            }

            .description {
                font-size: 13px;
                margin-bottom: 10px;
            }

            .review {
                color: $alt_color;
                font-weight: bold;
                text-transform: capitalize;
                font-size: 20px;
                border: 1px solid;
                padding: 10px;
                margin-bottom: 25px;
                border-radius: 5px;

                &:hover {
                    background-color: $alt_color;
                    color: $alt_2_color;
                }
            }
        }
    }
}
</style>