<template>
  <div class="header-img">
    <the-header />
  </div>
  <BaseContainer>

    <div class="brands">
      <div class="brand" v-for="brand in brands" :key="brand.id">
        <router-link :to="getBrand(brand.id)">
          <img :src="brand.logo" alt="Logo" />
        </router-link>
      </div>
    </div>
    <div class="contact">
      <h3>Contact our sales team</h3>
      <router-link to="/contactus">Lets explore how we can help you
        <font-awesome-icon :icon="['fas', 'circle-chevron-right']" />
      </router-link>
    </div>
  </BaseContainer>
</template>
  
<script >
import BaseContainer from '@/assets/bases/BaseContainer.vue';

export default {
  components: { BaseContainer },
  computed: {
    brands() {
      return this.$store.getters["brands/brands"];
    },

  },
  created() {
    this.loadBrands();
  },
  methods: {
    getBrand(brandId) {
      return `/brands/${brandId}`
    },
    async loadBrands() {
      try {
        await this.$store.dispatch("brands/loadBrands", { brandId: "" });
      }
      catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
  },

}; 
</script>
<style lang="scss" scoped>
@import '../scss/vars';

.header-img {
  background-image: url('../assets/images/productsBG.jpg');
  background-size: cover;
  height: 400px;
  overflow: hidden;
  border-radius: 0 0 15% 50%/0 0 30% 60%;

  @media (max-width: 767px) {
    border-radius: 0;
      overflow: visible;
    height: 150px;
  }

  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

  }
}

.brands {
  width: 100%;
  margin: 50px auto 50px;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  gap: 40px;

  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    max-width: 200px;
    overflow: hidden;
    background-color: $alt_1_BG_color;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }
}

.contact {
  margin-bottom: 100px;

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
  }

  a {
    font-size: 16px;
    line-height: 20px;
    color: $main_color;

    svg {
      color: $alt_color;
      font-size: 20px;

    }

  }
}
</style>