<template>
  <div class="base-container">
    <slot></slot>
  </div>
</template>
<style scoped lang="scss">
.base-container {
  margin: 0 auto;
  width: 75%;
  padding: 0 10px;
  // @media (max-width: 900px) {
  //   width: 80%;
  //  }
   @media (max-width: 767px) {
    width: 90%;
   }
}
</style>
