<template>
    <div class="header-img">
        <the-header />
    </div>
    <base-container>
        <form @submit.prevent="sendForm">
            <div class="form-part">
                <!-- <div class="name"> -->
                <div class="control">
                    <label for="firstName">Full Name</label>
                    <input :class="{ invalid: !firstName.isValid }" type="text" id="firstName"
                        v-model.trim="firstName.val" />
                    <p v-if="!firstName.isValid">Please type your name</p>
                </div>
                <!-- <div class="control">
                    <label for="lastName">LastName</label>
                    <input :class="{ invalid: !lastName.isValid }" type="text" id="lastName" v-model.trim="lastName.val" />
                    <p v-if="!lastName.isValid">Please type your name</p>
                </div> -->
                <!-- </div> -->
                <div class="control">
                    <label for="email">E-mail</label>
                    <input :class="{ invalid: !email.isValid }" type="email" id="email" v-model.trim="email.val" />
                    <p v-if="!email.isValid">Please type your email</p>
                </div>
                <div class="control">
                    <label for="phoneNumber">Phone Number</label>
                    <input :class="{ invalid: !phoneNumber.isValid }" type="text" id="phoneNumber"
                        v-model.trim="phoneNumber.val" />
                    <p v-if="!phoneNumber.isValid">Please type your Phone Number</p>
                </div>
            </div>

            <div class="form-part">

                <div class="control">
                    <label for="description">Anything else</label>
                    <textarea :class="{ invalid: !description.isValid }" id="description"
                        v-model.trim="description.val"></textarea>
                    <p v-if="!description.isValid">Please type your message</p>

                </div>
                <div class="accept">
                    <input type="checkbox" name="accept" id="accept">
                    <label for="accept">Bysubmitting this form, I aknowledge receipt of the Alfanar Privacy policy</label>
                </div>
                <div class="control">
                    <button type="submit">Send Email</button>
                </div>
                <p v-if="sentSucces" class="sent">Your message sent succesfuly</p>

            </div>
        </form>
    </base-container>
</template>
  
<script >
export default {
    data() {
        return {

            formIsValid: true,
            firstName: {
                val: '',
                isValid: true,
            },
            // lastName: {
            //     val: '',
            //     isValid: true,
            // },
            email: {
                val: '',
                isValid: true,
            },
            phoneNumber: {
                val: '',
                isValid: true,
            },
            // phone: {
            //     val: null,
            //     isValid: true,
            // },
            // country: {
            //     val: '',
            //     isValid: true,
            // },
            description: {
                val: '',
                isValid: true,
            },
            // budget: '',
            // lunchDate: '',
            sentSucces: false,
        }
    },

    computed: {
    },
    methods: {
        isValid() {
            this.formIsValid = true
            if (this.firstName.val === '') {
                this.firstName.isValid = false
                this.formIsValid = false
            } else {
                this.firstName.isValid = true
            }
            // if (this.lastName.val === '') {
            //     this.lastName.isValid = false
            //     this.formIsValid = false
            // } else {
            //     this.lastName.isValid = true
            // }
            if (this.email.val === '' || !this.email.val.includes('@')) {
                this.email.isValid = false
                this.formIsValid = false
            } else {
                this.email.isValid = true
            }
            if (this.phoneNumber.val === '') {
                this.phoneNumber.isValid = false
                this.formIsValid = false
            } else {
                this.phoneNumber.isValid = true
            }
            // if (this.phone.val === null) {
            //     this.phone.isValid = false
            //     this.formIsValid = false
            // } else {
            //     this.phone.isValid = true
            // }
            // if (this.country.val === '') {
            //     this.country.isValid = false
            //     this.formIsValid = false
            // } else {
            //     this.country.isValid = true
            // }
            if (this.description.val === '') {
                this.description.isValid = false
                this.formIsValid = false
            } else {
                this.description.isValid = true
            }
        },
        clearData() {
            this.firstName.val = ''
            this.lastName.val = ''
            this.email.val = ''
            this.companyName.val = ''

            // this.phone.val = null
            // this.country.val = ''
            this.description.val = ''

            this.firstName.isValid = true
            this.lastName.isValid = true
            this.email.isValid = true
            this.companyName.isValid = true
            // this.phone.isValid = true
            // this.country.isValid = true
            this.description.isValid = true
        },
        sendForm() {
            this.isValid()
            if (this.formIsValid) {
                this.send()
            }
        },
        async send(context, projectId) {
            const response = await fetch(`https://api.alfanar.ps/api/contact/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Make sure to set the correct content type
                    },

                    body: JSON.stringify({
                        first_name: this.firstName.val,
                        last_name: this.lastName.val,
                        email: this.email.val,
                        company_name: this.companyName.val,
                        message: this.description.val
                    }),
                });

            const responseData = await response.json();


            if (!response.ok) {
                const error = new Error(
                    responseData.message || "Failed to send request!"
                );
                throw error;
            }

            this.clearData()
            this.sentSucces = true
            setTimeout(() => (this.sentSucces = false), 5000)


        },
    },
}
</script>
 
<style scoped lang="scss">
@import '../scss/vars';

.header-img {
    background-image: url('../assets/images/contactusBG.jpg');
    background-size: cover;
    height: 400px;
  overflow: hidden;
  border-radius: 0 0 15% 50%/0 0 30% 60%;

  @media (max-width: 767px) {
    border-radius: 0;
    height: 150px;
    overflow: visible;

  }

  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

  }
}

form {
    margin: 50px auto 100px;
    width: 80%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 767px) {
        width: 80%;
        flex-direction: column;
    }

    .form-part {
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }

        .name {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

        }

        .control {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 6px auto;

            // @media (max-width: 767px) {
            //     margin: 5px auto;
            // }

            label {
                width: fit-content;
                margin-bottom: 6px;
                padding-left: 15px;

                // @media (max-width: 767px) {
                //     margin-bottom: 6px;
                //     font-size: 14px;
                // }
            }

            input {
                border: 1px solid $main_color;
                // border-bottom: 1px solid $main_color;
                height: 40px;
                background-color: transparent;
                caret-color: black;
                outline: none;
                border-radius: 10px;
                caret-color: $main_color;
                padding-left: 15px;
                transition: 0.5s;
                text-align: left;
                // @media (max-width: 767px) {
                //     height: 30px;
                //     font-size: 14px;
                // }
            }

            .invalid {
                border: 1px solid red;
            }

            textarea {
                border: 1px solid $main_color;
                background-color: transparent;
                caret-color: $main_color;
                outline: none;
                border-radius: 10px;
                padding-left: 15px;
                transition: 0.5s;
                height: 155px;
                text-align: left;

            }

            // input:hover,
            // textarea:hover,
            // select:hover {
            //     transform: scale(1.055) translateY(-4px);
            // }

            button {
                width: 100%;
                border-radius: 30px;
                border: none;
                height: 55px;
                background-color: $alt_3_BG_color;
                transition: 0.5s;
                color: $alt_2_color;
                margin-top: 10px;
            }

            a:hover,
            button:hover {
                transform: scale(0.85);
                background-color: $main_BG_color;
                color: white;

            }

            p {
                color: red;
            }

            // p {
            // @media (max-width: 767px) {
            //     height: 15px;
            //     font-size: 14px;
            // }
            // }
        }

        .accept {
            font-size: 10px;

            input {
                margin-right: 5px;
            }
        }

        .sent {
            text-transform: uppercase;
            color: green;
        }
    }

}
</style>
  