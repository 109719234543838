import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeVue.vue'
import AboutView from '../views/AboutVue.vue'
import NewsView from '../views/NewsVue.vue'

import BrandsView from '../views/BrandsVue.vue'
import Products from '../components/brands/Products.vue'
import ProductDetails from '../components/brands/ProductDetails.vue'

import ProjectsView from '../views/ProjectsVue.vue'
import ReferenceProject from '../components/projects/ReferenceProject.vue'

import SolutionsVue from '../views/SolutionsVue.vue'

import ContactUsVue from '../views/ContactUsVue.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/brands',
    name: 'brands',
    component: BrandsView
  },
  {
    path: '/brands/:brandId',
    component: Products,
    props: true,

  },
  {
    path: '/products/:productId',
    component: ProductDetails,
    props: true,

  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },
  {
    path: '/projects/:projectId',
    component: ReferenceProject,
    props: true,

  },
  {
    path: '/solutions',
    name: 'solutions',
    component: SolutionsVue
  }, {
    path: '/contactus',
    name: 'contactus',
    component: ContactUsVue
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
