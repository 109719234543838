export default {
  brand(state) {
    return state.brand;
  },
  brands(state) {
    return state.brands;
  },
  categories(state) {
    return state.categories;
  },
  products(state) {
    return state.products;
  },
  product(state) {
    console.log("product getters done...");
    return state.product;
  },
};
