//create app
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// Create Router
import router from './router'
app.use(router)

// Store
import store from './store/index.js';
app.use(store);

// General conponents
import BaseContainer from './assets/bases/BaseContainer.vue'
app.component('base-container', BaseContainer)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)
app.component('font-awesome-icon', FontAwesomeIcon)

import TheHeader from './assets/TheHeader.vue'
app.component('the-header', TheHeader)

import Slider from '../src/assets/Slider.vue'
app.component('slider', Slider)

// Mount app
app.mount('#app')
