import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "solution" }
const _hoisted_2 = { class: "main-img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "logos" }
const _hoisted_8 = { class: "logo" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.mainImg,
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString($props.title), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString($props.description), 1),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.logos, (logo) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("img", {
              src: logo,
              alt: ""
            }, null, 8, _hoisted_9)
          ]))
        }), 256))
      ])
    ])
  ]))
}