export default {
  // async registerCoach(context, data) {
  //   const userId = context.rootGetters.userId;
  //   const coachData = {
  //     firstName: data.first,
  //     lastName: data.last,
  //     description: data.desc,
  //     hourlyRate: data.rate,
  //     areas: data.areas,
  //   };
  //   // without this token our http request wii rejected
  //   const token = context.rootGetters.token;
  //   const response = await fetch(
  //     // `https://test-http-2-default-rtdb.firebaseio.com/coaches/${userId}.json`,
  //     `https://test-http-2-default-rtdb.firebaseio.com/coaches/${userId}.json?auth=${token}`,
  //     {
  //       method: 'PUT',
  //       body: JSON.stringify(coachData),
  //     }
  //   );

  //   // const responseData = await response.json();

  //   if (!response.ok) {
  //     // error ...
  //   }

  //   context.commit('registerCoach', {
  //     ...coachData,
  //     id: userId,
  //   });
  // },
  async loadSolutions(context, payload) {
    const response = await fetch(
      `https://api.alfanar.ps/api/solutions/`
    );
    const responseData = await response.json();
    if (!response.ok) {
      console.log("from error");

      const error = new Error(
        responseData.message || "Failed to fetch Solutions!"
      );
      throw error;
    }

    const solutions = [];
    for (const key in responseData) {
      const solution = {
        id: responseData[key].id,
        name: responseData[key].name,
        description: responseData[key].description,
        main_image: responseData[key].main_image,
        icon_1: responseData[key].icon_1,
        icon_2: responseData[key].icon_2,
        icon_3: responseData[key].icon_3,
        icon_4: responseData[key].icon_4,
      };
      solutions.push(solution);
    }
    context.commit("setSolutions", solutions);
  },
};
