<template>
  <!-- <the-header /> -->
  <router-view />
  <!-- <the-footer /> -->
</template>
<!-- <script lang="ts">
// import TheHeader from './assets/TheHeader.vue';
import TheFooter from './assets/TheFooter.vue';
export default {
  components: {
    // TheHeader,
    TheFooter,
  },
  methods: {
  },
}
</script>
<style lang="scss">
@import './scss/global_rules';
</style> -->
