<template>
    <router-link :to="getProjectId">
    <div class="cover">
        <div class="main-img">
            <img :src="mainImg" alt="Project logo">
        </div>
        <h3 class="title">{{ title }}</h3>
        <p class="description">{{ description }}</p>
        <!-- <router-link :to="getProjectId"> -->
            <span class="review">Review <font-awesome-icon :icon="['fas', 'circle-chevron-right']" /></span>
        <!-- </router-link> -->
    </div>
    </router-link>
</template>
    
<script>
export default {
    props: ['id', 'mainImg', 'title', 'description', 'project'],
    computed: {
        getProjectId() {
            return `/projects/${this.id}`
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.cover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background-color: $alt_2_BG_color ;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 15px 25px;

    &:hover {
        transform: scale(1.1);
    }

    .main-img {
        width: 100%;
        height: 150px;
        border-radius: 15px;

        @media (max-width: 767px) {
            height: 70px;
        }

        img {
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
        }
    }

    .title {
        color: $main_color;
        letter-spacing: 1px;
        text-transform: uppercase;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    .description {
        color: $alt_color;
        // text-align: justify;

        line-height: 25px;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }

    .review {
        color: $alt_color;

        svg {
            // color: $alt_color !important;
        }
    }
}
</style>